import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';
import './App.css';

import OfferDisplay from './OfferDisplay';
import { useMediaQuery } from 'react-responsive'

const DealerInfo = React.createContext();
window.DealerInfo = DealerInfo;

function App() {
  const [ offer_data, setOfferData ] = useState(null);
  const [ dealer_portal_settings, setDealerPortalSettings ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const { k: portal_key, d: dealer_id } = Object.fromEntries(new URLSearchParams(window.location.search).entries());
  const loadOfferData = useCallback(() => {
    if(portal_key && dealer_id){
      setLoading(true);
      return fetch(`/api/offer?key=${portal_key}&dealer_id=${dealer_id}`)
        .then(r => r.ok ? r.json() : r.text().then(t => Promise.reject(`Unexpected status ${r.status} at ${r.url}: ${t}`)))
        .then(({ missing_fields, offer_status, pdf_url, dealer_portal_settings, signature_url }) => {
          setOfferData({ missing_fields, offer_status, pdf_url, signature_url });
          setDealerPortalSettings(dealer_portal_settings);
          setLoading(false)
        })
        .catch(err => { setError(err); setLoading(false) })
    }
  }, [dealer_id, portal_key]);
  useEffect(() => {loadOfferData()}, [loadOfferData]);
  const big_screen = useMediaQuery({ query: '(min-width: 1224px)' })
  const dealer_info = { ...dealer_portal_settings, portal_key, dealer_id };
  const {
    bg_color,
    logo_url,
    text_color,
    dealer_name,
  } = dealer_info;
  return (
    <div className="App">
      {loading ? 'Cargando' : offer_data ? (
        <DealerInfo.Provider value={dealer_info}>
          <div style={{
            display: 'grid',
            height: '100vh',
            alignItems: 'stretch',
            gridTemplateColumns: big_screen ? '30% 70%' : '1fr',
            gridTemplateRows: big_screen ? '1fr' : '4fr 10fr',
          }}>
            <div style={{
              backgroundColor: bg_color,
              display: 'grid',
              gridTemplateRows: big_screen ? 'auto 1fr auto' : '1fr',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: '3em',
              }}>
                <img style={{
                  width: 'auto',
                  maxWidth: 'calc(100% - 6em)',
                  height: '100%',
                  maxHeight: '10em',
                }} src={logo_url} />
              </div>
              {big_screen && <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                fontSize: '3em',
                color: text_color,
                padding: '1rem',
                paddingTop: '10rem',
                textAlign: 'center',
              }}>
                Oferta de {dealer_name}
              </div>}
              {big_screen && <div style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                padding: '1em',
                color: text_color,
              }}>
                Made with ️🐼 by Walcu
        </div>}
            </div>
            <OfferDisplay
              reloadOfferData={loadOfferData}
              offer_data={offer_data}
            />
          </div>
        </DealerInfo.Provider>
      ) : (
        <div>{error}</div>
      )}
    </div>
  );
}

export default App;
