import React, {
  useContext,
}  from 'react';
import styled from 'styled-components'

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: text;
  outline: none;
  align-items: flex-start;
  border-radius: 6px;
  border: 2px solid lightgrey;
  padding-left: 3px;
  
  &:focus-within{
    span{
      color: ${props => props.accent_color} !important;
    }
    border: 2px solid ${props => props.accent_color};
  }
`;

const Field = ({value, onChange, label}) => {
  const {
    accent_color,
  } = useContext(window.DealerInfo);
  return (
    <FieldContainer
      tabindex={0}
      accent_color={accent_color}
    >
      <span style={{
        color: 'black',
        margin: '-0.5em 0em 0em 0.5em',
        backgroundColor: 'white',
        fontSize: '0.8em',
        lineHeight: '1em',
        padding: '0em 0.3em',
      }}>
        {label}
      </span>
      <div style={{
        minWidth: '0px',
        alignSelf: 'stretch',
        maxWidth: '100%',
      }}>
        <div style={{position: 'relative'}}>
          <div style={{display: 'flex'}}>
            <input style={{
              border: '0px',
              backgroundColor: 'white',
              outline: 'none',
              padding: '0.5em 0.3em',
              lineHeight: '1em',
              borderRadius: '3px 3px 3px 3px',
              width: '100%',
              resize: 'none',
              height: '2em',
            }} value={value} onChange={e => onChange(e.target.value)}/>
          </div>
        </div>
      </div>
    </FieldContainer>
  );
}

export default Field;
