import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';


import styled            from 'styled-components';
import Field             from './components/Field';

import "./index.css";

const Button = styled.button`
  padding: 1em;
  background-color: ${props => props.disabled ? 'lightgray' : props.accent_color};
  border: 1px solid lightgray;
  box-shadow: rgba(0, 0, 0, 0.18) 1px 1px 3px 0px;
  border-radius: 3px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`;


const OfferDisplay = ({
  offer_data,
  reloadOfferData,
}) => {
  const { missing_fields, offer_status, pdf_url, signature_url } = offer_data;
  const [ filled_values, setFilledValues ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState();
  const {
    accent_color,
    portal_key,
    dealer_id,
  } = useContext(window.DealerInfo);
  const sendOfferFields = useCallback(() => {
    setLoading(true);
    fetch(`/api/offer?key=${portal_key}&dealer_id=${dealer_id}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        filled_values,
      })
    })
      .then(r => r.ok ? r.json() : r.text().then(t => Promise.reject(`Unexpected status ${r.status} at ${r.url}: ${t}`)))
      .then(reloadOfferData)
      .then(() => setLoading(false))
      .catch(setError)
  }, [dealer_id, portal_key, filled_values, reloadOfferData]);
  if(error){
    return (
      <div style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '50px',
      }}>
        Lo sentimos, ha ocurrido un error desconocido con la generación de tu oferta.
      </div>
    )
  }
  if(loading)
    return (
      <div style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '50px',
      }}>
        Procesando tu oferta&nbsp;
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    );
  else return (
    <div style={{ padding: '0em' }}>
      {['finished', 'signature_requested', 'signed'].some(s => s === offer_status) && (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {signature_url ?
            <iframe style={{ border: '0px', flex: 1 }} title="Oferta" src={signature_url} /> :
            <iframe style={{ border: '0px', flex: 1 }} title="Oferta" src={pdf_url} />
          }
        </div>
      )}
      {(offer_status === 'draft' && missing_fields.length) ? <div style={{ display: 'flex', flexDirection: 'column', gap: '1em', height: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1em', justifyContent: 'center', height: '100%', padding: '0em 5em' }}>
          <h1>Por favor, rellena el siguiente formulario</h1>
          {missing_fields.map(f => (
            <Field
              key={f.key}
              label={f.key}
              value={filled_values[f.key]}
              field_descriptor={f.source_field}
              onChange={value => setFilledValues(prev => ({ ...prev, [f.key]: value }))}
            />
          ))}
        </div>
        <Button
          accent_color={accent_color}
          onClick={sendOfferFields}
        >
          Enviar
          </Button>
      </div> : null}
      {(offer_status === 'draft' && !missing_fields.length) ? <div style={{ display: 'flex', flexDirection: 'column', gap: '1em', height: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1em', justifyContent: 'center', height: '100%', padding: '0em 5em' }}>
          <span style={{ fontSize: '60px' }}>✅</span>
          <h1>Muchas gracias por rellenar el formulario, hemos notificado a tu asesor comerciar para que finalice el documento</h1>
        </div>
      </div> : null}
    </div>
  );
}

export default OfferDisplay;
